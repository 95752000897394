<template>
  <v-app>
    <SideBar v-if="login.auth" :drawer.sync="drawer" />

    <v-app-bar v-if="login.auth" app color="blue darken-2" dense dark>
      <v-app-bar-nav-icon class="white--text" @click="drawer = true" />

      <div class="d-flex align-center">
        <v-toolbar-title v-text="title" />
      </div>

      <v-spacer />

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon dark @click="logout">
            <v-icon v-text="'mdi-logout-variant'" />
          </v-btn>
        </template>
        <span v-text="'Cerrar sesión'" />
      </v-tooltip>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <v-footer v-if="login.auth" app padless>
      <v-row dense>
        <v-col cols="6">
          <small v-text="login.email" class="pl-1 font-weight-bold" />
        </v-col>
        <v-col cols="6" class="text-right">
          <small v-text="version" class="pr-1" />
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import Axios from "axios";
import { URL_API, headers } from "./control";
import SideBar from "./components/SideBar.vue";

export default {
  components: {
    SideBar,
  },

  data() {
    return {
      drawer: false,
      login: this.$store.getters.getLogin,
      title: "Sinergia Médica",
      version: "v1.24.08.23",
    };
  },

  methods: {
    logout() {
      this.$swal
        .fire({
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonText: "Sí",
          icon: "warning",
          text: `¿Confirma cerrar la sesión?`,
        })
        .then((response) => {
          if (response.isConfirmed) {
            const token = this.login.token;
            this.$store.dispatch("logOutAction");

            Axios.get(`${URL_API}/auth/logout`, headers(token)).then(
              (response) => {
                if (response.data.success) {
                  window.location.assign("/inicio_sesion");
                } else {
                  console.log(response.data.message);
                  this.$swal.fire({
                    showConfirmButton: false,
                    icon: response.data.success ? "success" : "error",
                    text: response.data.message,
                    timer: 2000,
                  });
                }
              }
            );
          }
        });
    },
  },
};
</script>

<style>
.toolbar {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.v-main {
  background-color: #f8f9fa;
}

.swal2-html-container,
.swal2-styled {
  font-family: "Roboto" !important;
}
</style>